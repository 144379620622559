import { debounce } from '@utils/debounce';

const useGeneralBlock = () => {
  const makeClickable = (element: HTMLElement | null): void =>  {
    let link = element?.querySelector(".link") as HTMLAnchorElement;
    window.location.href = link.getAttribute('href') as string;
  }
  
  const initializeEventListeners = () => {
    // Add "Display more" click event listeners
    document.querySelectorAll(".button--display-more").forEach((element) => {
      element.addEventListener("click", function() {
        const parent = element.parentElement as HTMLDivElement;
        const mainBody = parent.querySelector(".general-block__main-body") as HTMLDivElement;

        mainBody.style.maxHeight = "none"
        mainBody.classList.remove("general-block__main-body--truncated");
        parent.style.maxHeight = "none";
        (element as HTMLElement).style.display = "none";
      });
    });

    window.addEventListener('orientationchange', adjustHeight);
    window.addEventListener('hresize', adjustHeight);

    // Horizontal window resize events.
    let prevWidth = window.innerWidth;

    // Debounce the resize event
    window.addEventListener('resize', debounce(() => {
      const width = window.innerWidth;

      if (width !== prevWidth) {
        window.dispatchEvent(new CustomEvent('hresize'));
      }
      prevWidth = width;
    }, 200));
    
    // Add anchor link click event listeners to the whole block if whole link is active
    (document.querySelectorAll('.general-block__content--whole-link') as NodeListOf<HTMLElement>).forEach((generalBlockElement) => {
      generalBlockElement.addEventListener('click', () => {
        makeClickable(generalBlockElement);
      });
      generalBlockElement.addEventListener('keyup', (event) => {
        if (event?.key === 'Enter') {
          makeClickable(generalBlockElement);
        }
      });
    });
  }
  
  const adjustHeight = () => {
    const truncContainers = document.querySelectorAll('.general-block__main-body') as NodeListOf<HTMLElement>;

    truncContainers.forEach((truncContainer) => {
      let childrenHeight = 0;
      const children = truncContainer.children;
      const displayMoreElement = truncContainer.parentElement?.querySelector(".button--display-more") as HTMLElement;

      for (let j = 0; j < children.length; j++) {
        let child = children[j] as HTMLElement;

        childrenHeight = childrenHeight + child.offsetHeight;
      }

      if (childrenHeight < 300 || window.innerWidth > 767) {
        truncContainer.style.maxHeight = "none";
        truncContainer.classList.remove("general-block__main-body--truncated");

        if (!!displayMoreElement) {
          displayMoreElement.style.display = "none";
        }
      } else if (childrenHeight > 300 && window.innerWidth < 767) {
        truncContainer.classList.add("general-block__main-body--truncated");
        truncContainer.style.maxHeight = "";

        if (!!displayMoreElement) {
          displayMoreElement.style.display = "inline-flex";
        }
      }
    });
  }
  
  initializeEventListeners();
  adjustHeight();
}

document.addEventListener('DOMContentLoaded', useGeneralBlock);